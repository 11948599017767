import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import SectionHero from '../components/section-hero'
import Seo from '../components/seo'
import { useBusinessManImage } from '../hooks/businessman-hook'
import { ProductSection } from '../components/section'

const BlogPage = ({ data }) => {
	const posts = data.allMarkdownRemark.nodes

	return (
		<Layout>
			<Seo
				title="Blog | Rapid Fire Options"
				description="Discover the best options trading alerts, including day trading alerts and swing trading alerts, with Rapid Fire Options. The right alert can make a trade. The wrong one can cost you money. Join Rapid Fire Options Today!"
			/>
			<SectionHero
				image={useBusinessManImage()}
				title1="Options & Trading Blog"
				title2="Rapid Fire Options"
				body="Rapid Fire Options is a screener based service based on unusual activities in certain options spanning individual stocks, SPACs, ETFs, Futures, etc. You have the ability to pick which one you want to get into and leave others."
				button="Join Today!"
				bgPosition={{ backgroundPosition: '25% 25%' }}
			/>
			<div className="container-xl bg-base-200 justify-center px-5 py-24">
				<div className="text-center">
					<h1>Latest posts</h1>
					<hr className="hr-sm mx-auto my-5 border-primary" />
				</div>
				<div className="py-8 flex w-full items-center justify-center">
					<ol className="w-10/12 md:w-8/12 list-none">
						{posts.map(post => {
							const title = post.frontmatter.title || post.fields.slug

							return (
								<li
									className="p-3 my-8 bg-base-100 drop-shadow-lg"
									key={post.fields.slug}>
									<article
										className="post-list-item"
										itemScope
										itemType="http://schema.org/Article">
										<header>
											<h2 className="text-3xl">
												<Link to={post.fields.slug} itemProp="url">
													<span itemProp="headline">{title}</span>
												</Link>
											</h2>
											<small>{post.frontmatter.date}</small>
										</header>
										<section>
											<div
												dangerouslySetInnerHTML={{
													__html: post.frontmatter.description || post.excerpt
												}}
												itemProp="description"
												className="text-lg"
											/>
										</section>
									</article>
								</li>
							)
						})}
					</ol>
				</div>
			</div>
			<ProductSection />
		</Layout>
	)
}

export default BlogPage

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				author {
					name
				}
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				excerpt(pruneLength: 250)
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					description
				}
			}
		}
	}
`
